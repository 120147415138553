import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();

import { HotifyApiHelper } from "./helper/HotifyApiHelper";
const hotifyApiHelper = new HotifyApiHelper();

//has some api's from ai team

export class AimoSolutionsServices {
    constructor() {}
    getChartData(screenName, instance, graphName, kpiMarket) {
        const uri = `aimo/rhyme/getGraphdata?screenName=${screenName}&instance=${instance}&graphTitle=${graphName}&kpiMarket=${kpiMarket}`;
        return apiHelper.get(uri);
    }
    getAllModel(modelType, subId, KPI) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}`;
        return hotifyApiHelper.get(uri);
    }
    getOverallAttribution(modelType, subId, KPI) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}`;
        return hotifyApiHelper.get(uri);
    }
    getInternalChartData(modelType, subId, KPI) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}`;
        return hotifyApiHelper.get(uri);
    }
    
    getExternalChartData(modelType, subId, KPI) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}`;
        return hotifyApiHelper.get(uri);
    }
    getMediaChartData(modelType, subId, KPI) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}`;
        return hotifyApiHelper.get(uri);
    }
    getInvsVsRevenueData(modelType, subId, levelOne, levelTwo) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=leads&levelOne=${levelOne}&levelTwo=${levelTwo}`;
        return hotifyApiHelper.get(uri);
    }
    getAttributionbyChannelData(modelType, KPI, subId) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}&category=channel`;
        return hotifyApiHelper.get(uri);
    }  
    getAttributionLevelTwo(modelType, subId, KPI, levelOne, levelTwo) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}&levelOne=${levelOne}&levelTwo=${levelTwo}`;
        return hotifyApiHelper.get(uri);
    } 

    getInvestmentvsroiChart(modelType, subId, KPI) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}&category=investmentvsrevenue`;
        return hotifyApiHelper.get(uri);
    } 

    getInvestmentvsroiChartlevel2(modelType, subId, KPI, levelOne, levelTwo) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}&levelOne=${levelOne}&levelTwo=${levelTwo}`;
        return hotifyApiHelper.get(uri);
    } 
    
    getStatChartData(modelType, subId, KPI) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}`;
        return hotifyApiHelper.get(uri);
    } 
    getPredictedData(data) {
        const uri = `aimo/load-json?filename=${data.fileName}&growthVal=${data.growthVal}&market=${data.market}`
        return apiHelper.post(uri, data)
    }
    getVariance(growthVal, market) {
        const uri = `aimo/get-variance?growthVal=${growthVal}&market=${market}`
        return apiHelper.get(uri)
    }
    getPredictedThirdLevel(key, market, data, edited, value, readFile) {
        if (value === undefined) {
            value = 0
        }
        const uri = `aimo/getdata?key=${key}&values=&kpiMarket=${market}&edited=${edited}&value=${value}&readFile=${readFile}`
        return apiHelper.post(uri, data);
    }
    getVarianceAfterEdit(revenue, market, growthVal) {
        const uri = `aimo/get-varianceAfterEdtit?revenue=${revenue}&market=${market}&growthValue=${growthVal}`
        return apiHelper.get(uri)
    }
    getAgilePlan(predictedrevenue, predictedbudget, variance) {
        const uri = `aimo/get-agileplan?predictedrevenue=${predictedrevenue}&predictedbudget=${predictedbudget}&variance=${variance}`
        return apiHelper.get(uri)
    }
    getAgileChartData(fileName, market, data) {
        const uri = `aimo/load-json-agile?fileName=${fileName}&kpiMarket=${market}`
        return apiHelper.post(uri, data)
    }
    getLifetimeChartData(modelType, subId, KPI) {
        const uri = `process/v1/aimo?clientId=${subId}&keyword=${modelType}&kpi=${KPI}`
        return hotifyApiHelper.get(uri)
    }
}