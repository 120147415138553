import { render, staticRenderFns } from "./MultiBreakdownChannelWiseData.vue?vue&type=template&id=3637b5e3&scoped=true&"
import script from "./MultiBreakdownChannelWiseData.vue?vue&type=script&lang=js&"
export * from "./MultiBreakdownChannelWiseData.vue?vue&type=script&lang=js&"
import style0 from "./MultiBreakdownChannelWiseData.vue?vue&type=style&index=0&id=3637b5e3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3637b5e3",
  null
  
)

export default component.exports